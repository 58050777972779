import {Injectable} from '@angular/core';
import {DefaultService} from './default.service';
import {RequestsService} from './requests.service';
import {User} from '../admin-pages/users/users.objects';
import {LoginUser, RegisterUser, ResetPasswordUser} from '../auths-pages/auth-pages.objects';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthService extends DefaultService {

  private token: string = '';
  private authUser: User = new User();

  constructor(private requestService: RequestsService) {
    super();
  }

  public authenticate(user: LoginUser): Observable<any> {
    const adminUser = {email: user.email, password: user.password};
    return this.requestService.sendPostRequest('auth/authenticate', adminUser, this.token);
  }

  public register(user: RegisterUser): Observable<any> {
    const adminUser = {full_name: user.full_name, email: user.email, phone: user.phone, password: user.password};
    return this.requestService.sendPostRequest('auth/register', adminUser, this.token);
  }

  public forgotPassword(user: LoginUser): Observable<any> {
    const adminUser = {email: user.email};
    return this.requestService.sendPostRequest('auth/request_password', adminUser, this.token);
  }

  public resetPassword(user: ResetPasswordUser): Observable<any> {
    return this.requestService.sendPostRequest('auth/reset_password', user, this.token);
  }

  public logout() {
    localStorage.setItem('token', '');
    localStorage.setItem('user', JSON.stringify([]));
  }

  public setToken(token: string) {
    if (token) {
      this.token = token;
      localStorage.setItem('token', this.token);
    }
  }

  public getToken(): string {
    const token = localStorage.getItem('token');
    if (token) {
      return token;
    } else {
      return '';
    }
  }

  public setUser(user: any) {
    if (user) {
      localStorage.setItem('user', JSON.stringify(user));
    }
  }

  public getUser(): User {
    const adminUser = localStorage.getItem('user');
    if (adminUser) {
      this.authUser = JSON.parse(adminUser);
      return this.authUser;
    }
    return new User();
  }
}
