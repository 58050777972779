import {NgModule} from '@angular/core';
import {ExtraOptions, RouterModule, Routes} from '@angular/router';
import {AuthGuard} from './services/auth.guard';

const routes: Routes = [
  {
    path: 'admin',
    loadChildren: () => import('./admin-pages/admin-pages.module')
      .then(m => m.AdminPagesModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'auths',
    loadChildren: () => import('./auth-pages/auth-pages.module')
      .then(m => m.AuthPagesModule),
  },
  {
    path: 'auth',
    loadChildren: () => import('./auths-pages/auths-pages.module')
      .then(m => m.AuthsPagesModule),
  },
  {path: '', redirectTo: 'auth/login', pathMatch: 'full'},
  {path: '**', redirectTo: 'auth/login'},
];

const config: ExtraOptions = {
  useHash: true,
};

@NgModule({
  imports: [RouterModule.forRoot(routes, config)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
