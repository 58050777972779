import {Injectable} from '@angular/core';
import {CanActivate, Router} from '@angular/router';
import {NbAuthService} from '@nebular/auth';
import {Observable} from 'rxjs';
import {tap} from 'rxjs/operators';
import {AuthService} from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  private condition: Observable<boolean>;

  constructor(private authService: AuthService,
              private _router: Router) {
    this.condition = new Observable<boolean>(ob => {
      ob.next(false);
    });
  }

  canActivate(): Observable<boolean> {
    this.condition = new Observable<boolean>(ob => {
      ob.next(this.authService.getToken() !== '');
    });
    return this.condition;
  }

}
