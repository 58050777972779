import {Injectable} from '@angular/core';
import {HttpClient, HttpErrorResponse, HttpHeaders, HttpParams} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {environment} from '../../environments/environment.prod';

@Injectable({
  providedIn: 'root'
})
export class RequestsService {
  public api_root_url = environment.root_url + '/api/';

  constructor(private httpRequest: HttpClient) {
  }

  public sendGetRequest(routeName: string, token: string, parameter: HttpParams = new HttpParams()): Observable<any> {
    const header = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + token,
    });
    return this.httpRequest.get(this.api_root_url + routeName, {headers: header, params: parameter});
  }


  public sendPostRequest(routeName: string, body: any, token: string, header: any = null): Observable<any> {
    if (header == null) {
      header = new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token
      });
    }
    return this.httpRequest.post(this.api_root_url + routeName, body, {headers: header, reportProgress: true, observe: 'events'});
  }

  public sendPatchRequest(routeName: string, body: any, token: string): Observable<any> {
    const header = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + token
    });
    const param = new HttpParams().set('id', body.id);
    return this.httpRequest.patch(this.api_root_url + routeName, body, {headers: header, params: param, observe: 'events'});
  }

  public sendDeleteRequest(routeName: string, body: any, token: string): Observable<any> {
    const header = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + token
    });
    const param = new HttpParams().set('id', body.id);
    return this.httpRequest.delete(this.api_root_url + routeName, {headers: header, params: param});
  }

  public errorMgmt(error: HttpErrorResponse): any {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      // Get client-side error
      errorMessage = error.error.message;
    } else {
      // Get server-side error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    return throwError(errorMessage);
  }

  public sendDeleteRequest2(routeName: string, body: any, token: string): Observable<any> {
    const header = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + token
    });
    const param = new HttpParams().set('role_id', body.role_id).set('user_id', body.user_id);
    return this.httpRequest.delete(this.api_root_url + routeName, {headers: header, params: param});
  }

  // public sendPutRequest(routeName: string, body: any, header): Observable<any> {
  //   return this.httpRequest.put(this.api_root_url + routeName, body, header);
  // }
}
